<template>
  <div class="font-baloo2 text-center space-y-8">
    <h2 class="text-2xl">
      MERHABA {{ userName }}, HOŞGELDİNİZ!
    </h2>
    <div class="flex flex-wrap justify-center font-CardenioModernBold">
      <router-link
        v-for="item in items"
        :key="item.title"
        :to="{ name: item.route, }"
      >
        <div
          class="h-48 w-48 my-4 mx-1 md:mx-2 lg:mx-8 transition transform duration-300 motion-safe:hover:scale-110 bg-gray-100 rounded-2xl drop-shadow-lg flex flex-col justify-around"
        >
          <img
            :src="item.image"
            class="h-32 w-32 m-auto"
          >
          <div class="text-2xl w-full text-center bg-[#008c7f] text-white rounded-2xl">
            {{ item.title }}
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { getAbsolutePath } from "@/api/api";
import DashedButton from "@/components/DashedButton.vue";

import { useStore } from "@/store";
import ImagePlans from "@/assets/user-panel-1-plans.png";

const items = [
    {
        title: "PROGRAM",
        route: "theme",
        image: ImagePlans,
    },
];

export default defineComponent({
    components: { DashedButton, },
    setup() {
        const store = useStore();
        return { userName: store.user.name, items };
    },
});
</script>

<style scoped>
</style>