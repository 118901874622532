<template>
  <main class="md:container md:max-w-4xl select-none font-baloo2">
    <h1 class="text-4xl">
      HAKKIMIZDA
    </h1>
    <div class="text-xl my-8 space-y-4">
      <p>BEM Boğaziçi Eğitim Hizmetleri ve Yayıncılık, eğitim ve danışmanlık alanlarındaki faaliyetlerini 1997 yılından bu yana İstanbul’daki merkezinde sürdürmektedir. Multibem ve Adım Adım Değerler markalarını çatısı altında toplayan BEM Boğaziçi Eğitim Hizmetleri ve Yayıncılık kurucuları yirmi yılı aşkın süredir okul öncesi eğitim alanında kaliteli hizmet üretmek için gayret etmektedirler. Alanda uzman ve tecrübeli kimseler tarafından özenle hazırlanan “Multibem Erken Çocukluk Eğitim Programı” ile Türkiye çapında yüzü aşkın eğitim kurumu ile işbirliği içinde olan BEM Boğaziçi Eğitim Hizmetler bünyesinde Adım Adım Değerler markasını da bulundurmaktadır. Değerlerimizin de dahil edildiği bir okul öncesi eğitiminin neslimiz için çok kıymetli olduğunu düşünen kurucularımız Adım Adım Değerler markasıyla pek çok ebeveynin ve öğretmenin okul öncesi ve ilkokul eğitimi alanında yaşadığı boşluğu doldurmayı hedeflemektedir. </p>
      <p>
        Multibem hakkında daha fazla bilgi edinmek için burayı ziyaret edebilirsiniz.
        <a href="https://www.multibem.com.tr">multibem.com.tr</a>
      </p>
      <p>
        MultiBEM yayıncılık hakkında daha fazla bilgi edinmek için burayı ziyaret edebilirsiniz.
        <a href="https://www.multibemkitap.com">multibemkitap.com</a>
      </p>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { BookOpenIcon, CollectionIcon, SpeakerphoneIcon } from "@heroicons/vue/solid";

export default defineComponent({
    components: {
    },
    setup() {
        
        return {
         
        };
    },
});
</script>

<style lang="scss" scoped>
</style>