<template>
  <div class="font-baloo2 text-center space-y-8">
    <h2 class="text-3xl">
      MERHABA {{ userName }}, HOŞGELDİNİZ!
    </h2>
    <div class="flex flex-wrap justify-center font-CardenioModernBold">
      <router-link
        v-for="item in items"
        :key="item.title"
        :to="{ name: item.route, }"
      >
        <div
          class="h-48 w-48 my-4 mx-1 md:mx-2 lg:mx-8 transition transform duration-300 motion-safe:hover:scale-110 bg-gray-100 rounded-2xl drop-shadow-lg flex flex-col justify-around"
        >
          <img
            :src="item.image"
            class="h-32 w-32 m-auto"
          >
          <div class="text-2xl w-full text-center bg-[#008c7f] text-white rounded-2xl">
            {{ item.title }}
          </div>
        </div>
      </router-link>
      <!--          <DashedButton-->
      <!--            :to="{ name: 'theme' }"-->
      <!--            title="TEMALAR"-->
      <!--            :image="ImageActivityBoook"-->
      <!--          />-->
      <!--          <DashedButton-->
      <!--            :to="{ name: 'teacher-students' }"-->
      <!--            title="ÖĞRENCİLERİM"-->
      <!--            :image="ImageStudents"-->
      <!--          />-->
      <!--          <DashedButton-->
      <!--            :to="{ name: 'teacher-available-themes' }"-->
      <!--            title="ÖĞRETMEN PANELİ"-->
      <!--            :image="ImageTeacherPanel"-->
      <!--          />-->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { getAbsolutePath } from "@/api/api";
import DashedButton from "@/components/DashedButton.vue";
import ImagePlans from "@/assets/user-panel-1-plans.png";
import ImageStudents from "@/assets/user-panel-2-students.png";
import ImageLibrary from "@/assets/user-panel-3-library.png";
import ImageStandards from "@/assets/user-panel-4-standards.png";
import ImageTeacherPanel from "@/assets/teacher-panel.png";

import { useStore } from "@/store";

const items = [
    {
        title: "PROGRAM",
        route: "theme",
        image: ImagePlans,
    },
    {
        title: "ÖĞRENCİLERİM",
        route: "teacher-students",
        image: ImageStudents,
    },
    // {
    //     title: "ÖĞRETMEN PANELİ",
    //     route: "teacher-available-themes",
    //     image: ImageTeacherPanel,
    // },
    // {
    //     title: "KÜTÜPHANE",
    //     route: "theme",
    //     image: ImageLibrary,
    // },
    // {
    //     title: "STANDARTLAR",
    //     route: "theme",
    //     image: ImageStandards,
    // },
];

export default defineComponent({
    components: { DashedButton, },
    setup() {
        const store = useStore();
        return {
            // ImageActivityBoook,
            // ImageStudents,
            // ImageTeacherPanel,
            userName: store.user.name,
            items,
        };
    },
});
</script>

<style scoped>
</style>