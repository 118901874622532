<template>
  <main class="md:container md:max-w-4xl select-none font-baloo2">
    <h1 class="text-4xl">
      PROGRAMIMIZ
    </h1>
    <div class="text-xl my-8 space-y-4">
      <p>Adım Adım Değerler Eğitim Programı öğretmeni ve öğrenciyi geliştiren bir programdır. Bu program, 5 yaş grubu sınıflar için beş ayrı başlık altında deneyim odaklı planlar içermektedir. Alanda uzman ve uzun yıllardır tecrübeli kimselerin rehberliğinde, kendi doğasına uygun olarak her başlık için ayrı bir metot geliştirilmiştir. Programın başlıca özellikleri şu şekildedir:</p>
      <ul>
        <li>Programın içinde matbu ve dijital olarak 16 tane siyer hikaye kitabı ve 10 tane peygamber hikayeleri kitapları vardır. Bu kitaplar programı satın alan tüm sınıfların kütüphanesinde bulunması için matbu olarak verilir. Ayrıca öğretmen bu kitaplara dijital olarak sınıf ortamında da ulaşabilir.</li>
        <li>Programa pek çok resimli kart dahildir. Öğretmen bu resimli kartlarla sınıf içinde daha nitelikli bir öğrenme ortamını çocuklara sağlayabilir.</li>
        <li>İki eğitim dönemi boyunca 20 tane Hadis-i Şerif’e değinilmektedir. Davranış odaklı olan Hadis-i Şerifler ayrı ayrı 4 planda uygulama olarak gerçekleştirilmektedir.</li>
        <li>İki eğitim dönemi boyunca çocukların on tane peygamberin hayatını dinlemesi ve hayatlarındaki bazı temaları birebir deneyimlemesi hedeflenmektedir.</li>
        <li>Bir yıl boyunca Peygamberimiz (sav)’in hayatından 16 farklı hatıratı, çeşitli hikaye anlatma yöntemleri ile çocuklara sunulmaktadır.</li>
        <li>İki eğitim dönemi boyunca her gün uygulanan Kur’an-ı Kerim’i yüzünden okuma ve ezberleme planları içermektedir.</li>
        <li>Allah’ı yarattıkları üzerinden tefekkür etme becerisini çocuklara kazandırmak niyeti ile haftada bir kere olmak üzere iki dönem boyunca Allah’ın 32 tane ismi sohbet/tartışma yöntemleri ile işlenmektedir.</li>
        <li>Program kapsamında birinci eğitim dönemi başlamadan önce birkaç oturum şeklinde çevrimiçi ortamda yoğunlaştırılmış bir öğretmen eğitimi sağlanmaktadır. Bunun yanında yıl boyunca periyotlarla çevrimiçi öğretmen eğitimleri ile öğretmenlerimiz desteklenmektedir.</li>
        <li>Program kapsamında okullara bir adet çember panosu gönderilmektedir. Öğretmen her sabah çember panosunun yardımı ile çocuklarla çember saati yapar.</li>
        <li>Yine program kapsamında Peygamberimiz (sav)’in hayatından bazı kesitleri kronolojik olarak temsil eden bir siyer halısı programın kapsamına dahildir.</li>
      </ul>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { BookOpenIcon, CollectionIcon, SpeakerphoneIcon } from "@heroicons/vue/solid";

export default defineComponent({
    components: {
    },
    setup() {
        
        return {
         
        };
    },
});
</script>

<style lang="scss" scoped>
</style>