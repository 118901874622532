<template>
  <Popover
    class="relative z-10"
  >
    <div class="max-w-7xl mx-auto md:px-0 px-6">
      <div
        class="flex justify-between items-center border-b-2 border-primary py-1 mb-8 md:justify-start md:space-x-10"
      >
        <div class="flex justify-start lg:w-0 lg:flex-1">
          <router-link :to="{ name: 'landing' }">
            <img
              src="@/assets/nav_logo.png"
              class="w-20 h-20"
            >
          </router-link>
        </div>
        <div class="-mr-2 -my-2 md:hidden">
          <PopoverButton
            class="bg-primary rounded-md p-2 inline-flex items-center justify-center text-white hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
          >
            <span class="sr-only">Open menu</span>
            <MenuIcon
              class="h-6 w-6"
              aria-hidden="true"
            />
          </PopoverButton>
        </div>
        <nav
          class="hidden md:flex space-x-10 font-DJBMissLiz text-xl"
        >
          <router-link
            v-for="l in links"
            :key="l.title"
            :to="{ name: l.route }"
            active-class="border-primary"
            class="font-extrabold text-gray-500 hover:text-gray-900 border-b-2"
            :class="{'pt-6': isLandingPage}"
          >
            {{ l.title }}
          </router-link>
          <!-- <a href="#" class="text-base font-medium text-gray-500 hover:text-gray-900">Docs</a> -->
        </nav>
        <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          <span class="mr-4 font-CardenioModernBold text-xl" v-if="store.user.availableGrades?.length == 1">
            {{ grade }}
          </span>
          <select class="mr-4 rounded-md" v-if="store.user.availableGrades?.length > 1" v-model="store.user.grade">
            <option v-for="item in store.user.availableGrades" :value="item">{{ getGradename(item) }}</option>
          </select>
          <LoginButton />
        </div>
      </div>
    </div>

    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <PopoverPanel
        v-slot="{ close }"
        focus
        class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
      >
        <div
          class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50"
        >
          <div class="pt-5 pb-6 px-5">
            <div class="flex items-center justify-between">
              <div>
                <img
                  class="h-8 w-auto"
                  src="@/assets/nav-robo-icon.png"
                  alt="Workflow"
                >
              </div>
              <div class="-mr-2">
                <PopoverButton
                  class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
                >
                  <span class="sr-only">Close menu</span>
                  <XIcon
                    class="h-6 w-6"
                    aria-hidden="true"
                  />
                </PopoverButton>
              </div>
            </div>
            <div class="mt-6">
              <nav class="grid gap-y-8">
                <router-link
                  v-for="l in links"
                  :key="l.title"
                  v-slot="{ href, navigate }"
                  :to="{ name: l.route }"
                  active-class="border-primary"
                  class="text-base font-extrabold text-gray-500 hover:text-gray-900 border-b-2"
                >
                  <PopoverButton
                    as="a"
                    :href="href"
                    @click="()=>{navigate(); close();}"
                  >
                    {{ l.title }}
                  </PopoverButton>
                </router-link>
              </nav>
            </div>
          </div>
          <div class="py-6 px-5 space-y-6">
            <div>
              <PopoverButton
                :as="LoginButton"
                class="w-full"
              />
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script lang="ts">
import {computed, defineComponent, ref, watch} from "vue";
import { RouterLink } from "vue-router";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import {
    BookmarkAltIcon,
    CalendarIcon,
    ChartBarIcon,
    CursorClickIcon,
    MenuIcon,
    PhoneIcon,
    PlayIcon,
    RefreshIcon,
    ShieldCheckIcon,
    SupportIcon,
    ViewGridIcon,
    XIcon,
} from "@heroicons/vue/outline";
import { ChevronDownIcon } from "@heroicons/vue/solid";

import { useStore } from "@/store";
import { useRouter, useRoute } from "vue-router";
import LoginButton from "../LoginButton.vue";

const landingLinks = [
    {
        title: "Anasayfa",
        route: "landing",
    },
    {
        title: "Hakkımızda",
        route: "hakkimizda",
    },
    {
        title: "Bakış Açımız",
        route: "bakis-acimiz",
    },
    {
        title: "Programımız",
        route: "programimiz",
    },
    {
        title: "İletişim",
        route: "iletisim",
    },
];

const teacherLinks = [
    {
        title: "Başlangıç",
        route: "home",
    },
    {
        title: "Program",
        route: "theme",
    },
    {
        title: "Yıllık Plan",
        route: "teacher-syllabus",
    },
    {
        title: "Haftalık Akış",
        route: "teacher-lesson-flow",
    },
    {
        title: "Öğrencilerim",
        route: "teacher-students",
    },
];

const studentLinks = [
    {
        title: "Başlangıç",
        route: "home",
    },
    {
        title: "Program",
        route: "theme",
    },
    // {
    //   title: "My Students",
    //   route: "teacher-students",
    // },
];

export default defineComponent({
    components: {
        Popover,
        PopoverButton,
        PopoverPanel,
        ChevronDownIcon,
        MenuIcon,
        XIcon,
        LoginButton,
    },
    setup() {
        const searchDropdown = ref(false);
        const isPopoverOpen = ref(false);

        const store = useStore();
        const router = useRouter();
        const route = useRoute();

        const isLandingPage = computed(() => {
            return route.matched.some(x => x.name == "landing-parent");
        });

        const showSearchDropdown = () => {
            searchDropdown.value = true;
        };

        const hideSearchDropdown = () => {
            searchDropdown.value = false;
        };

        const links = computed(() => {
            if(isLandingPage.value){
                return landingLinks;
            }
            else if (store.user.isTeacher) {
                return teacherLinks;
            } else if (store.user.isStudent) {
                return studentLinks;
            } else {
                return [];
            }
        });
        
        const grade = computed(() =>{
          return getGradename(store.user.grade!);
        });

        const getGradename = (grade:string) => {
          switch (grade){
            case "Green":
              return "3 YAŞ";
            case "Red":
              return "4 YAŞ";
            case "Orange":
              return "5-6 YAŞ";
            default:
              return null;
          }
        }

        watch(()=> store.user.grade, () => router.replace("theme"))

        router.afterEach(x => { isPopoverOpen.value = false; });

        return {
            links,
            isLandingPage,
            isPopoverOpen,
            RouterLink,
            LoginButton,
            landingLinks,
            grade,
          store,
            getGradename,
        };
    }
});
</script>
  