<template>
  <router-link :to="props.to">
    <div
      class="h-48 w-48 m-4 transition transform duration-300 motion-safe:hover:scale-110 border-dashed border-primary border-4 rounded-xl"
    >
      <img
        :src="props.image"
        class="h-32 w-32 m-auto"
      >
      <div class="m-auto text-2xl w-full text-center">
        {{ props.title }}
      </div>
    </div>
  </router-link>
</template>

<script lang="ts">
import { defineComponent, PropType, toRefs } from "vue";
import { RouteLocationRaw } from "vue-router";

export default defineComponent({
    props: {
        to: { type: Object as PropType<RouteLocationRaw> },
        image: { type: String, required: true },
        title: { type: String, required: true },
    },
    setup(props) {

        return {
            props
        };
    }
});
</script>

<style scoped>
</style>