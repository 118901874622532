<template>
  <div
    v-if="theme"
    class="justify-center font-baloo2 select-none mt-4"
  >
    <theme-button
      :theme="theme"
      text-color="#ffffff"
      bgcolor="#008c7f"
    />
    <div v-if="store.user.role == 'teacher' || theme.name.indexOf('TATİL') != -1">
      <div class="grid md:grid-cols-5 sm:grid-cols-1 gap-4 md:justify-center font-bold mt-4">
        <router-link
          v-for="i in 10"
          :to="{ name: 'teacher-lesson', params: { theme: theme.order, lesson: i } }"
          class="block transition transform duration-300 motion-safe:hover:scale-110 justify-center h-16 transition text-white text-2xl flex content-center flex-wrap p-3 font-josefin relative flex h-12 rounded-tl-3xl rounded-br-3xl border-4 border-double"
          :class="i<6 ? 'bg-primary-dark' : 'bg-primary'"
        >
          <div>
            <div class="mb-auto mt-auto flex">
              <span class="inline-block align-middle text-white mt-1">{{ days[(i-1)%5] }}</span>
            </div>
          </div>
          <div />
        </router-link>
      </div>
    </div>
    <div class="flex flex-wrap mt-8 justify-center font-CardenioModernBold">
      <component
        :is="!isContentAvailable(i) ? 'span' : 'router-link'"
        v-for="(c,i) in contentTypes"
        :to="{ name: c.route, params: { theme: theme.order } }"
        :disabled="isContentAvailable(i)"
      >
        <div
          class="h-48 w-48 my-4 mx-1 md:mx-2 lg:mx-8 transition transform duration-300 motion-safe:hover:scale-110 bg-gray-100 rounded-2xl drop-shadow-lg flex flex-col justify-around"
          :class="{ filter: !isContentAvailable(i), grayscale: !isContentAvailable(i) }"
        >
          <img
            :src="c.icon"
            class="h-32 w-32 m-auto"
          >
          <div class="text-2xl w-full text-center bg-[#008c7f] text-white rounded-2xl">
            {{ c.title }}
          </div>
        </div>
      </component>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent} from "vue";
import { RouteLocation, RouteLocationRaw } from "vue-router";

import { themesData } from "@/models/themes";
import { contentTypes } from "@/models/content";
// import { getTheme } from "@/api/content";
import { useStore } from "@/store";
import ThemeButton from "@/components/ThemeButton.vue";
import {days} from "@/models/days";

export default defineComponent({
    components: {
        ThemeButton

    },
    props: {
        theme: { type: Number, required: true },
    },
    async setup(props) {
        const store = useStore();

        const theme = await store.user.getTheme(props.theme);

        const isContentAvailable = (i: number): boolean => {
          
            if(store.user.grade == "Green" && (i == 5 || i == 4)){
                return false;
            }

            // if (store.user.role == "student") {
            //     return store.user.availableThemes[props.theme.toString()]?.[i];
            // } else {
            return true;
            // }
        };

        return {
            theme,
            contentTypes,
            isTeacher: store.user.isTeacher,
            isContentAvailable,
            days,
            store,
        };
    }
});

export const getTitle = async (route: RouteLocation) =>{
    const store = useStore();
    const themes = await store.user.getThemes();
    return `${themes.find(x => x.order == parseInt(route.params.theme as string))?.name}`;
};

export const getBreadcrumb = async (route: RouteLocation) => 
    ({title: await getTitle(route), route: {name: "theme-content", params: {theme: route.params.theme}} as RouteLocationRaw});

</script>

<style scoped>
</style>