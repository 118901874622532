<template>
  <router-view />
  <loading
    v-model:active="store.app.loading"
    :can-cancel="false"
    :is-full-page="true"
    color="#008c7f"
  />
</template>

<script lang="ts">
import { defineComponent, toRef } from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import {useStore} from "@/store";

export default defineComponent({
    name: "App",
    components: {
        Loading,
    },
    setup(){
        const store = useStore();

        return {store};
    }
});
</script>

<style>
body{
  /*background-image: url('@/assets/bg.jpg');*/
}
</style>