<template>
  <footer class="bg-primary-dark flex flex-col justify-center">
    <div class="max-w-7xl mx-auto py-6 px-4 overflow-hidden sm:px-6 lg:px-8">
      <nav
        class="-mx-5 -my-2 flex flex-wrap justify-center"
        aria-label="Footer"
      >
        <div class="px-5 py-2">
          <router-link
            class="text-base text-white hover:text-gray-900"
            :to="{name:'landing'}"
          >
            Anasayfa
          </router-link>
        </div>
        <div class="px-5 py-2">
          <router-link
            class="text-base text-white hover:text-gray-900"
            :to="{name:'hakkimizda'}"
          >
            Hakkımızda
          </router-link>
        </div>
        <div class="px-5 py-2">
          <router-link
            class="text-base text-white hover:text-gray-900"
            :to="{name:'bakis-acimiz'}"
          >
            Bakış Açımız
          </router-link>
        </div>
        <div class="px-5 py-2">
          <router-link
            class="text-base text-white hover:text-gray-900"
            :to="{name:'programimiz'}"
          >
            Programımız
          </router-link>
        </div>
        <div class="px-5 py-2">
          <router-link
            class="text-base text-white hover:text-gray-900"
            :to="{name:'iletisim'}"
          >
            İletişim
          </router-link>
        </div>
      </nav>
      <p class="mt-8 text-center text-base text-white">
        &copy; 2022 Multibem
      </p>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    setup() {

        return {};
    }
});
</script>

<style scoped>
footer {
  height: 160px;
  background-image: url("@/assets/footer.png");
  background-repeat: repeat-x;
}
</style>