<template>
  <div class="md:container md:max-w-4xl">
    <SectionTitle
      class="my-8 text-center"
      title="NEDEN ADIM ADIM DEĞERLER EĞİTİM PROGRAMI"
    />
    <div class="font-DJBMissLiz text-2xl text-center">
      <p>
        Çocuklar; bulundukları ortamda geçerli olan değerleri önce hissederler, sonra öğrenirler ve sonra da ahlak olarak edinirler. Çocuğun hayatında değerler eğitiminde başrolü oynayan iki kişi vardır: Annesi ve babası. Okul öncesi dönemde öğretmen de anne ve baba kadar çocuğun hayatında etkili olmaya başlar. Alanda uzman ve tecrübeli kişiler olarak özenle hazırladığımız Adım Adım Değerler Eğitim Programı ile bu dünya yolculuğunda aynı nesil derdini taşıdığımız tüm ebeveynlere ve öğretmenlere rehber olmayı istiyoruz.
      </p>
    </div>

    <SectionTitle
      class="my-8 text-center"
      title="ADIM ADIM DEĞERLER EĞİTİM PROGRAMI"
    />
    <div
      v-for="item in benefits"
      :key="item.title"
      class="rounded-md flex flex-col md:flex-row drop-shadow-lg bg-[#efefef] mt-8"
    >
      <div class="w-full md:w-1/3 flex flex-col bg-primary-dark rounded-md overflow-hidden">
        <img
          :src="item.image"
          :alt="item.title"
        >
        <h2 class="text-center text-white text-3xl p-2">
          {{ item.title }}
        </h2>
      </div>
      <div class="w-full md:w-2/3 p-4 text-xl font-DJBMissLiz flex flex-col justify-center">
        <ul class="space-y-2">
          <li
            v-for="(bullet, i) in item.items"
            :key="i"
            class="flex space-x-2 bg-no-repeat pl-10"
            :class="[item.bullet]"
          >
            <span>{{ bullet }}</span>
          </li>
        </ul>
        <!--        <div class="flex justify-center">-->
        <!--          <a-->
        <!--            href="#"-->
        <!--            class="w-full md:w-48 flex justify-center mt-4 pt-2 py-1 px-4 border border-transparent rounded-3xl shadow-sm text-2xl font-medium text-white hover:bg-primary bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"-->
        <!--          >İncele</a>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import SectionTitle from "./SectionTitle.vue";

import Remarks1 from "@/assets/landing/remarks_1_aile_kimdir.jpg";
import Remarks2 from "@/assets/landing/remarks_2_cocuk_kimdir.jpg";
import Remarks3 from "@/assets/landing/remarks_3_ogretmen_kimdir.jpg";

import Bullet1 from "@/assets/landing/bullet_1.png";

const benefits = [{
    image: Remarks1,
    title: "ÇOCUK KİMDİR?",
    items: [
        "Çocuk eşref-i mahlukattır.",
        "Çocuk, öğretmene ve aileye emanettir.",
        "Çocuk kendi hayatında aktif söz sahibidir.",
        "Çocuk, öğretmenin ve ailesinin mülkü değildir.",
        "Çocuk, Allah’ın ayrı olarak hesaba alacağı bir kul ve bireydir.",
        "Çocuk saygıdeğerdir.",
        "Çocuk her şeyi hisseder ve anlar.",
    ],
    bullet:"bullet-1",
},
{
    image: Remarks2,
    title: "ÖĞRETMEN KİMDİR?",
    items: [
        "Daha ahlaklı bir öğretmen olmak için zihni ve kalbi hep açıktır.",
        "Keşfetmek niyeti ile çocukları merhametle ve hikmetle gözlemler.",
        "Çocukları birer emanet ve sadaka-i cariye olarak görür.",
        "Allah’ın her çocuğun bünyesinde gizlediği nimetler ve yetenekler olduğunu bilir.",
        "Onlara en az yetişkinlere davrandığı kadar nazik davranır.",
        "Onların sürekli kendisini gözlemlediğini ve model aldığını bilir.",
    ],
    bullet:"bullet-2",
},
{
    image: Remarks3,
    title: "AİLE KİMDİR?",
    items:[
        "Çocuklar için ilk ve ve en etkili öğrenme ortamı ailedir.",
        "Çocuk, ebeveynlerinin penceresinden dünyayı anlamlandırır.",
        "Çocuk maruz kaldığı duygular ile kendi duygu dünyasını şekillendirir.",
        "Anne ve baba çocuğun hayatındaki iki ana karakterdir.",
        "Çocuk en iyi modelleyerek öğrenir.",
        "Bu yüzden içinde bulunduğu aile ortamı çocuğa şekil verir.",
    ],
    bullet:"bullet-3",
}];

export default defineComponent({
    components: {
        SectionTitle,
    },
    setup() {

        return {  benefits };
    }
});
</script>

<style lang="scss" scoped>
.bullet-1{
  background-image: url('/src/assets/landing/bullet_1.png');
}

.bullet-2{
  background-image: url('/src/assets/landing/bullet_2.png');
}

.bullet-3{
  background-image: url('/src/assets/landing/bullet_3.png');
}
</style>