<template>
  <div class="relative">
    <!--    <div-->
    <!--      class="absolute inset-0 flex items-center"-->
    <!--      aria-hidden="true"-->
    <!--    >-->
    <!--      <div class="w-full border-t-2 border-black border-dashed" />-->
    <!--    </div>-->
    <div class="relative flex justify-center">
      <span class="px-3 text-5xl font-CardenioModernBold font-medium text-gray-900">{{ $props.title }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        title: String,
    },
    setup() {

        return {};
    }
});
</script>

<style lang="scss" scoped>
</style>