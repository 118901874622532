<template>
  <div>Manager</div>
  <router-link :to="{ name: 'teacher-list' }">
    Teacher List
  </router-link>
  <br>
  <router-link :to="{ name: 'student-list' }">
    Student List
  </router-link>
  <br>
  <router-link :to="{ name: 'class-list' }">
    Class List
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    setup() {

        return {  };
    }
});
</script>

<style lang="scss" scoped>
</style>