
const images: Record<string, any> = import.meta.globEager("../assets/content-*.png");

export const contentTypes = [
    {
        title: "KUR'AN-I KERİM",
        icon: "",
        route: "kuran",
    },
    {
        title: "EZBER",
        icon: "",
        route: "ezber",
    },
    {
        title: "HADİS-İ ŞERİF",
        icon: "",
        route: "hadis",
    },
    {
        title: "SİYER",
        icon: "",
        route: "siyer",
    },
    {
        title: "PEYGAMBERLER TARİHİ",
        icon: "",
        route: "peygamberler-tarihi",
    },
    {
        title: "İLAHİ",
        icon: "",
        route: "ilahiler",
    },
    {
        title: "BÜLTEN",
        icon: "",
        route: "bulten",
    },
    {
        title: "ETKİNLİK",
        icon: "",
        route: "etkinlik",
    },
];

Object.keys(images).forEach((v,i)=>{
    contentTypes[i].icon = images[v].default;
});
