<template>
  <div class="bg-primary p-4 my-12 shadow-xl">
    <div class="space-y-4 my-4">
      <h2 class="text-5xl w-full text-center text-white">
        PEKİ, PROGRAMDA NELER VAR?
      </h2>
      <div class="flex flex-wrap justify-around">
        <div
          v-for="item in items"
          :key="item.title"
          class="bg-white border-0 rounded-2xl overflow-hidden w-32 mb-3"
        >
          <img
            class="m-auto"
            :src="item.image"
          >
          <div class="pt-0 p-4">
            <p
              class="text-xl text-center font-bold"
              v-html="item.title"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

const images: Record<string, any> = import.meta.globEager("../../assets/landing/icon_*.png");
const titles = [
    "Kuran-ı <br/> Kerim",
    "Hikaye Kitapları",
    "Bültenler",
    "İlahiler",
    "Aktiviteler",
    "Aktivite <br/> Kitapları",
    "Resimli Kartlar",
    "Standartlar",
    "Planlar",
    "Öğrencilerim",
];

const items = Object.keys(images).map((v, i) => ({
    title: titles[i],
    image: images[v].default,
}));

export default defineComponent({

    setup() {
        return {
            items
        };
    }
});
</script>

<style lang="scss" scoped>
.robo-numbers-bg {
    background-color: #678c36;
}
</style>