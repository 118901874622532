<template>
  <main class="select-none font-baloo2">
    <Banner />
    <WhyRobo />
    <RoboInNumbers />
    <Books />
    <!--    <Remarkable />-->
    <!--    <HowToUse />-->
  </main>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { BookOpenIcon, CollectionIcon, SpeakerphoneIcon } from "@heroicons/vue/solid";
import Tab from "@/components/Tab.vue";
import WhyRobo from "./landing/WhyRobo.vue";
import RoboInNumbers from "./landing/RoboInNumbers.vue";
import Remarkable from "./landing/Remarkable.vue";
import HowToUse from "./landing/HowToUse.vue";
import Banner from "./landing/Banner.vue";
import Books from "@/views/landing/Books.vue";

export default defineComponent({
    components: {
        Books,
        Tab,
        WhyRobo,
        RoboInNumbers,
        Remarkable,
        HowToUse,
        Banner,
    },
    setup() {
        const books = [{
            title: "Fun At School",
            // icon: ShipmentOnlineMonitor,
            route: "#"
        }];
        const tabs = [
            { name: "Books", href: "#", icon: BookOpenIcon, current: false },
            { name: "Flash Cards", href: "#", icon: CollectionIcon, current: false },
            { name: "Audio Book", href: "#", icon: SpeakerphoneIcon, current: false },
            { name: "Books", href: "#", icon: BookOpenIcon, current: false },
            { name: "Flash Cards", href: "#", icon: CollectionIcon, current: false },
        ];
        const people = [
            {
                name: "Fun At School",
                imageUrl: "/landing/s.png"
            },
            {
                name: "Healthy Snacks",
                imageUrl: "/landing/snacks.png"
            },
            {
                name: "Basket Tomatoes",
                imageUrl: "/landing/basket.png"
            },
            {
                name: "ELPHY\u2019s DREAM",
                imageUrl: "/landing/dream.png"
            },
            // More people...
        ];
        return {
            books,
            tabs,
            people
        };
    },
});
</script>

<style lang="scss" scoped>
</style>