<template>
  <div
    class="w-full [--swiper-navigation-color:#008c7f] [--swiper-theme-color:#008c7f] [--swiper-navigation-size:32px] mb-8"
  >
    <SectionTitle
      class="my-8 text-center"
      title="ADIM ADIM DEĞERLER KİTAPLIĞI"
    />
    <swiper
      :slides-per-view="1"
      :space-between="50"
      :modules="modules"
      loop
      navigation
      :autoplay="{delay:4000}"
      :breakpoints="{
        500: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        },
        1400: {
          slidesPerView: 6,
        },
      }"
      class="h-full swiper px-12"
    >
      <swiper-slide
        v-for="book in books"
        class="group"
      >
        <a
          :href="book.url"
          class="relative block overflow-hidden"
        >
          <div class="absolute inset-0 bg-black bg-opacity-30 hidden group-hover:flex w-full h-full z-10 flex-col justify-end items-center transition transform duration-300 opacity-0 motion-safe:hover:opacity-100">
            <button class="text-white py-1 px-1 mb-1 round-md bg-primary-dark rounded-full flex text-xl items-center">
              <SearchCircleIcon class="w-12 h-12 mr-2" />
              <span class="mr-2">İncele</span>
            </button>
          </div>
          <img
            class="drop-shadow-xl rounded-lg"
            :src="book.image"
          >
        </a>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent} from "vue";
import {Swiper, SwiperSlide} from "swiper/vue";
import {Autoplay, Navigation, Pagination,} from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { SearchCircleIcon } from "@heroicons/vue/solid";

import SectionTitle from "./SectionTitle.vue";

const images: Record<string, any> = import.meta.globEager("../../assets/kitaplar/*.jpg");

const bookLinks = [
    "https://www.multibemkitap.com/adem-peygamber",
    "https://www.multibemkitap.com/altin-top",
    "https://www.multibemkitap.com/anne-cicegi",
    "https://www.multibemkitap.com/arapca-kesfi-1",
    "https://www.multibemkitap.com/arapca-kesfi-2",
    "https://www.multibemkitap.com/atkili-armut",
    "https://www.multibemkitap.com/boya-eglen-ogren-2",
    "https://www.multibemkitap.com/boya-eglen-ogren-3",
    "https://www.multibemkitap.com/bu-deve-neden-kizdi",
    "https://www.multibemkitap.com/cocuklarla-hadisler-1",
    "https://www.multibemkitap.com/cocuklarla-hadisler-2",
    "https://www.multibemkitap.com/davud-peygamber",
    "https://www.multibemkitap.com/degerler-egitimi-gunlugu",
    "https://www.multibemkitap.com/dunyanin-en-guzel-kalbi",
    "https://www.multibemkitap.com/dut-yagmuru",
    "https://www.multibemkitap.com/elif-ba",
    "https://www.multibemkitap.com/eyub-peygamber",
    "https://www.multibemkitap.com/hikayelerle-harflerin-yolculugu",
    "https://www.multibemkitap.com/hos-geldin-ramazan-1",
    "https://www.multibemkitap.com/ibrahim-peygamber",
    "https://www.multibemkitap.com/kanguru-kardesler",
    "https://www.multibemkitap.com/kolyeli-minareler",
    "https://www.multibemkitap.com/kuran-dunyasina-yolculuk-harfler",
    "https://www.multibemkitap.com/kuran-dunyasina-yolculuk-harekeler",
    "https://www.multibemkitap.com/musa-peygamber",
    "https://www.multibemkitap.com/nuh-peygamber",
    "https://www.multibemkitap.com/peygamber-hikayeleri-1",
    "https://www.multibemkitap.com/peygamber-hikayeleri-2",
    "https://www.multibemkitap.com/sahnedeki-beyaz-kelebekler",
    "https://www.multibemkitap.com/salih-peygamber",
    "https://www.multibemkitap.com/sevgi-zinciri",
    "https://www.multibemkitap.com/sifa-kitabi",
    "https://www.multibemkitap.com/suleyman-peygamber",
    "https://www.multibemkitap.com/yunus-peygamber",
    "https://www.multibemkitap.com/yusuf-peygamber",
];

export default defineComponent({
    components: {
        SectionTitle,
        Swiper,
        SwiperSlide,
        SearchCircleIcon,
    },
    setup() {

        const books = computed(() => {
            return Object.keys(images).map((v, i) => ({
                url: bookLinks[i],
                image: images[v].default,
            }));
        });

        return {modules: [Navigation, Pagination, Autoplay], books};
    }
});
</script>

<style lang="scss" scoped>
.title-shadow {
  text-shadow: 0px 0px 10px #ffffff, 0px 0px 20px #ffffff,
  0px 0px 30px #ffffff;
}

//:deep(.swiper img){
//  object-fit: cover;
//  width: 100%;
//  height: 100%;
//}
</style>