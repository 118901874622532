<template>
  <main class="md:container md:max-w-4xl select-none font-baloo2">
    <h1 class="text-4xl">
      İLETİŞİM
    </h1>
    <div class="text-xl my-8 space-y-4 grid grid-cols-1 gap-4">
      <div class="h-72">
        <iframe
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://maps.google.com/maps?q=Multibem&#038;t=m&#038;z=12&#038;output=embed&#038;iwloc=near"
          title="Multibem"
          aria-label="Multibem"
          class="w-full h-full"
        />
      </div>
      <div>
        <ul class="space-y-4">
          <li class="flex items-center">
            <img
              class="w-16 h-16 mr-4"
              src="@/assets/landing/iletisim_1.png"
            >Adres: Küçük Çamlıca Mah. Küçük Çamlıca Cad. No:39 Üsküdar / İstanbul
          </li>
          <li class="flex items-center">
            <img
              class="w-16 h-16 mr-4"
              src="@/assets/landing/iletisim_2.png"
            ><a href="tel:+902163188834">0216 318 88 34</a>
          </li>
          <li class="flex items-center">
            <img
              class="w-16 h-16 mr-4"
              src="@/assets/landing/iletisim_3.png"
            ><a href="mailto:degerler@multibem.com.tr">degerler@multibem.com.tr</a>
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {BookOpenIcon, CollectionIcon, SpeakerphoneIcon} from "@heroicons/vue/solid";

export default defineComponent({
    components: {},
    setup() {

        return {};
    },
});
</script>

<style lang="scss" scoped>
</style>