<template>
  <div class="md:container md:max-w-6xl h-80 [--swiper-navigation-color:white] [--swiper-theme-color:white] [--swiper-navigation-size:32px]">
    <swiper
      :slides-per-view="1"
      :space-between="50"
      :modules="modules"
      loop
      navigation
      :autoplay="{delay:4000}"
      :pagination="{ clickable: true }"
      class="h-full swiper"
    >
      <swiper-slide>
        <div class="w-2/4 md:hidden md:w-1/2 text-white absolute bottom-12 left-14 p-x-4 text-4xl md:text-5xl">
          "Değerler Her Zaman Her Yerde"
        </div>
        <img 
          class="md:hidden"
          src="@/assets/landing/slider-01.jpg"
        >
        <img
          class="hidden md:block"
          src="@/assets/landing/slider-01-md.jpg"
        >
      </swiper-slide>
      <swiper-slide>
        <div class="w-2/4 md:hidden md:w-1/2 text-white absolute bottom-12 left-14 p-x-4 text-4xl md:text-5xl">
          "Din eğitimi ailede başlar"
        </div>
        <img
          class="md:hidden"
          src="@/assets/landing/slider-02.jpg"
        >
        <img
          class="hidden md:block"
          src="@/assets/landing/slider-02-md.jpg"
        >
      </swiper-slide>
      <swiper-slide>
        <div class="md:hidden text-white absolute bottom-12 left-14 p-x-4 text-4xl md:text-5xl">
          "Yaşayan Din Eğitimi"
        </div>
        <img
          class="md:hidden"
          src="@/assets/landing/slider-03.jpg"
        >
        <img
          class="hidden md:block"
          src="@/assets/landing/slider-03-md.jpg"
        >
      </swiper-slide>
      <swiper-slide>
        <div class="w-2/4 md:hidden md:w-1/2 text-white absolute bottom-12 left-14 p-x-4 text-4xl md:text-5xl">
          "Din eğitimi öğretilmez, hayatın içinde edinilir."
        </div>
        <img
          class="md:hidden"
          src="@/assets/landing/slider-04.jpg"
        >
        <img
          class="hidden md:block"
          src="@/assets/landing/slider-04-md.jpg"
        >
      </swiper-slide>
    </swiper>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {Swiper, SwiperSlide} from "swiper/vue";
import {Autoplay, Navigation, Pagination,} from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import SectionTitle from "./SectionTitle.vue";

export default defineComponent({
    components: {
        SectionTitle,
        Swiper,
        SwiperSlide
    },
    setup() {

        return {modules: [Navigation, Pagination, Autoplay],};
    }
});
</script>

<style lang="scss" scoped>
.title-shadow {
  text-shadow: 0px 0px 10px #ffffff, 0px 0px 20px #ffffff,
  0px 0px 30px #ffffff;
}

:deep(.swiper img){
  object-fit: cover;
  width: 100%;
  height: 100%;
}
</style>