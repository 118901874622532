import {
    createRouter,
    createWebHashHistory,
    RouteLocation,
    RouteLocationRaw,
    RouteRecordRaw,
    START_LOCATION
} from "vue-router";

import Main from "@/components/layout/Main.vue";

import {getBreadcrumb as getThemeBreadcrumb, getTitle as getThemeTitle} from "@/views/Theme.vue";

// import Songs from "@/views/teacher/Songs.vue";

import LandingSkeletonVue from "@/components/layout/LandingSkeleton.vue";
import LandingView from "@/views/Home.vue";
import LoginVue from "@/views/Login.vue";
// import ErrorPage from "../views/error-page/Main.vue";

import {useStore} from "@/store";
import {computed, defineAsyncComponent, h} from "vue";
import {roleGuard} from "./roleGuard";
import BakisAcimiz from "@/views/landing/subpages/BakisAcimiz.vue";
import Hakkimizda from "@/views/landing/subpages/Hakkimizda.vue";
import Programimiz from "@/views/landing/subpages/Programimiz.vue";

import {days} from "@/models/days";
import Iletisim from "@/views/landing/subpages/Iletisim.vue";

import TeacherHome from "@/views/teacher/Home.vue";
import StudentHome from "@/views/student/Home.vue";
import ManagerHome from "@/views/manager/Home.vue";

export const getLessonTitle = (route: RouteLocation) =>
    `${days[parseInt(route.params.lesson-1)%5]}`;

export const getLessonBreadcrumb = (route: RouteLocation) =>
    ({
        title: getLessonTitle(route),
        route: {
            name: "teacher-lesson",
            params: {theme: route.params.theme, lesson: route.params.lesson}
        } as RouteLocationRaw
    });

const getHomePageForUser = () => {
    const store = useStore();
    if (store.user.isTeacher) {
        return TeacherHome;
    } else if (store.user.isStudent) {
        return StudentHome;
    } else if (store.user.isManager) {
        return ManagerHome;
    }
    throw "No homepage for user found";
};

const generateThemeContentPages = (forLesson: boolean) => {

    const lessonRoutePartial = forLesson ? "/lesson/:lesson" : "";
    const pathPrefix = `:theme${lessonRoutePartial}`;
    const namePrefix = forLesson ? "lesson-" : "";

    return [{
        path: `${pathPrefix}/kuran`,
        name: `${namePrefix}kuran`,
        component: () => import("@/views/teacher/Flashcards.vue"),
        props: true,
        meta: {
            breadcrumb: async (route: RouteLocation) => [await getThemeBreadcrumb(route), {title: "Kuran"}],
            title: "KUR'AN-I KERİM",
        }
    },
    {
        path: `${pathPrefix}/ezber`,
        name: `${namePrefix}ezber`,
        component: () => import("@/views/teacher/Ezber.vue"),
        props: true,
        meta: {
            breadcrumb: async (route: RouteLocation) => [await getThemeBreadcrumb(route), {title: "Ezber"}],
            title: "EZBER",
        }
    },
    {
        path: `${pathPrefix}/hadis`,
        name: `${namePrefix}hadis`,
        component: () => import("@/views/teacher/Hadis.vue"),
        props: true,
        meta: {
            breadcrumb: async (route: RouteLocation) => [await getThemeBreadcrumb(route), {title: "Hadis"}],
        },
        title: "HADİS-İ ŞERİF",
    },
    {
        path: `${pathPrefix}/siyer`,
        name: `${namePrefix}siyer`,
        component: () => import("@/views/teacher/Siyer.vue"),
        props: true,
        meta: {
            breadcrumb: async (route: RouteLocation) => [await getThemeBreadcrumb(route), {title: "Siyer"}],
            title: "SİYER",
        }
    },
    {
        path: `${pathPrefix}/peygamberler-tarihi`,
        name: `${namePrefix}peygamberler-tarihi`,
        component: () => import("@/views/teacher/PeygamberTarihi.vue"),
        props: true,
        meta: {
            breadcrumb: async (route: RouteLocation) => [await getThemeBreadcrumb(route), {title: "Peygamberler Tarihi"}],
            title: "PEYGAMBERLER TARİHİ",
        }
    },
    {
        path: `${pathPrefix}/ilahiler`,
        name: `${namePrefix}ilahiler`,
        component: () => import("@/views/teacher/Ilahiler.vue"),
        props: true,
        meta: {
            breadcrumb: async (route: RouteLocation) => [await getThemeBreadcrumb(route), {title: "İlahiler"}],
            title: "İLAHİ",
        }
    },
    {
        path: `${pathPrefix}/bulten`,
        name: `${namePrefix}bulten`,
        component: () => import("@/views/teacher/Newsletter.vue"),
        props: true,
        meta: {
            breadcrumb: async (route: RouteLocation) => [await getThemeBreadcrumb(route), {title: "Bülten"}],
            title: "BÜLTEN",
        }
    },
    {
        path: `${pathPrefix}/etkinlik`,
        name: `${namePrefix}etkinlik`,
        component: () => import("@/views/teacher/ActivityBook.vue"),
        props: true,
        meta: {
            breadcrumb: async (route: RouteLocation) => [await getThemeBreadcrumb(route), {title: "Etkinlik"}],
            title: "ETKİNLİK",
        }
    }
    ];
};

const routes: RouteRecordRaw[] = [
    {
        path: "/app",
        component: Main,
        redirect: {name: "home"},
        meta: {
            role: "loggedIn"
        },
        children: [
            {
                path: "home",
                name: "home",
                component: { render: () => h(getHomePageForUser()) },
                
            },
            {
                path: "theme",
                name: "theme",
                component: () => import("@/views/ThemeList.vue"),
            },
            {
                path: "theme",
                name: "theme-children",
                redirect: {name: "theme"},
                children: [
                    {
                        path: ":theme",
                        name: "theme-content",
                        component: () => import("@/views/Theme.vue"),
                        props: true,
                        meta: {
                            breadcrumb: async (route: RouteLocation) => [{
                                title: await getThemeTitle(route),
                                route: {name: "theme", params: {theme: route.params.theme}} as RouteLocationRaw
                            }],
                        }
                    },
                    {
                        path: ":theme/lesson/:lesson",
                        name: "teacher-lesson",
                        component: () => import("@/views/teacher/Lesson.vue"),
                        props: true,
                        meta: {
                            breadcrumb: async (route: RouteLocation) => [
                                await getThemeBreadcrumb(route),
                                getLessonBreadcrumb(route),
                            ],
                        }
                    },
                    ...generateThemeContentPages(true),
                    //bare content
                    // {
                    //     path: ":theme/kuran",
                    //     name: "kuran",
                    //     component: () => import("@/views/teacher/Flashcards.vue"),
                    //     props: true,
                    //     meta: {
                    //         breadcrumb: (route: RouteLocation) => [getThemeBreadcrumb(route), {title: "Kuran"}],
                    //     }
                    // },
                    // {
                    //     path: ":theme/ezber",
                    //     name: "ezber",
                    //     component: () => import("@/views/teacher/Ezber.vue"),
                    //     props: true,
                    //     meta: {
                    //         breadcrumb: (route: RouteLocation) => [getThemeBreadcrumb(route), {title: "Ezber"}],
                    //     }
                    // },
                    // {
                    //     path: ":theme/hadis",
                    //     name: "hadis",
                    //     component: () => import("@/views/teacher/Flashcards.vue"),
                    //     props: true,
                    //     meta: {
                    //         breadcrumb: (route: RouteLocation) => [getThemeBreadcrumb(route), {title: "Hadis"}],
                    //     }
                    // },
                    ...generateThemeContentPages(false),
                    {
                        path: ":theme/activity-book",
                        name: "activity-book",
                        component: () => import("@/views/teacher/ActivityBook.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => [getThemeBreadcrumb(route), {title: "Activity Book"}],
                        }
                    },
                    {
                        path: ":theme/story",
                        name: "story",
                        component: () => import("@/views/teacher/Story.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => [getThemeBreadcrumb(route), {title: "Story"}],
                        }
                    },
                    // {
                    //     path: ":theme/songs",
                    //     name: "songs",
                    //     component: Songs,
                    //     props: true,
                    //     meta: {
                    //         breadcrumb: (route: RouteLocation) => [getThemeBreadcrumb(route), {title: "Songs"}],
                    //     }
                    // },
                    {
                        path: ":theme/flashcards",
                        name: "flashcards",
                        component: () => import("@/views/teacher/Flashcards.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => [getThemeBreadcrumb(route), {title: "Flashcards"}],
                        }
                    },
                    {
                        path: ":theme/circle-time",
                        name: "circle-time",
                        component: () => import("@/views/teacher/CircleTime.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => [getThemeBreadcrumb(route), {title: "Circle Time"}],
                        }
                    },
                    {
                        path: ":theme/newsletter",
                        name: "newsletter",
                        component: () => import("@/views/teacher/Newsletter.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => [getThemeBreadcrumb(route), {title: "Newsletter"}],
                        }
                    },
                    {
                        path: ":theme/extra-activities",
                        name: "extra-activities",
                        component: () => import("@/views/teacher/ExtraActivities.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => [getThemeBreadcrumb(route), {title: "Extra Activities"}],
                        }
                    },
                    {
                        path: ":theme/project",
                        name: "project",
                        component: () => import("@/views/teacher/Project.vue"),
                        props: true,
                        meta: {
                            breadcrumb: (route: RouteLocation) => [getThemeBreadcrumb(route), {title: "Project"}],
                        }
                    },
                    //
                ]
            },
            {
                path: "my-students",
                name: "teacher-students",
                component: () => import("@/views/teacher/MyStudents.vue"),
                meta: {
                    role: "teacher"
                }
            },
            {
                path: "available-themes",
                name: "teacher-available-themes",
                component: () => import("@/views/teacher/AvailableThemes.vue"),
                meta: {
                    role: "teacher"
                }
            },
            {
                path: "syllabus",
                name: "teacher-syllabus",
                component: () => import("@/views/teacher/Syllabus.vue"),
                meta: {
                    role: "teacher"
                }
            },
            {
                path: "lesson-flow",
                name: "teacher-lesson-flow",
                component: () => import("@/views/teacher/LessonFlow.vue"),
                meta: {
                    role: "teacher"
                }
            },

        ],
    },
    {
        path: "/manager",
        component: () => import("@/components/layout/ManagerMain.vue"),
        redirect: {name: "manager-home"},
        meta: {
            role: "school_manager"
        },
        children: [
            {
                path: "home",
                name: "manager-home",
                component: () => import("@/views/manager/Home.vue"),
            },
            {
                path: "teacher-list",
                name: "teacher-list",
                component: () => import("@/views/manager/TeacherList.vue"),
            },
            {
                path: "student-list",
                name: "student-list",
                component: () => import("@/views/manager/StudentList.vue"),
            }, {
                path: "class-list",
                name: "class-list",
                component: () => import("@/views/manager/ClassList.vue"),
            },
        ],
    },
    {
        path: "/",
        name: "landing-parent",
        component: LandingSkeletonVue,
        redirect: {name: "landing"},
        children: [
            {
                path: "landing",
                name: "landing",
                component: LandingView
            },
            {
                path: "login",
                name: "login",
                component: LoginVue
            },
            {
                path: "bakis-acimiz",
                name: "bakis-acimiz",
                component: BakisAcimiz
            },
            {
                path: "hakkimizda",
                name: "hakkimizda",
                component: Hakkimizda
            },
            {
                path: "programimiz",
                name: "programimiz",
                component: Programimiz
            },
            {
                path: "iletisim",
                name: "iletisim",
                component: Iletisim
            },
        ]
    },
    // {
    //   path: "/login",
    //   name: "login",
    //   component: Login
    // },
    {
        path: "/:pathMatch(.*)*",
        component: Main
    }
];

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || {left: 0, top: 0};
    }
});

const generateContentBreadcrumb = (route: RouteLocation, title: string) =>
    [
        getThemeBreadcrumb(route),
        getLessonBreadcrumb(route),
        {title}
    ];

router.beforeEach(async (to, from, next) => {
    const store = useStore();

    if (typeof to.matched[to.matched.length - 1].components?.default === "function") {
        store.app.loading = true;
    }

    if (from === START_LOCATION) {
        const jwt = localStorage.getItem("key");
        if (jwt != null) {
            await store.user.getAccountInfo();
        }
    }
    next();
});

router.afterEach(async () => {
    const store = useStore();
    store.app.loading = false;
});

router.beforeEach(roleGuard);

export default router;
