<template>
  <div class="md:container">
    <!--    <DemoStory v-model="storyBook" />-->
    <!--    <DemoVideo v-model="storyVideo" />-->
    <SectionTitle
      class="my-8"
      title="What is remarkable in Roboland?"
    />
    <div class="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-16 mt-6 content-around">
      <div
        v-for="b in items"
        :key="b.title"
        class="shadow-2xl rounded-2xl text-black overflow-hidden lg:max-w-sm m-auto green-border cursor-pointer"
        @click="b.action()"
      >
        <img
          class="m-auto rounded-lg"
          :src="b.image"
        >
        <div class="p-4">
          <h3 class="text-3xl text-center">
            {{ b.title }}
          </h3>
        </div>
      </div>
    </div>
        
    <!-- <div class="grid grid-cols-2">
            <div v-for="i in 4" :key="i" class="bg-white overflow-hidden mb-4 border-0 rounded-lg">
                <img class="m-auto border-0 rounded-lg" src="https://dummyimage.com/320x320.png" />
                <p class="text-center text-2xl mt-1">Storybook</p>
            </div>
        </div>-->
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";

import SectionTitle from "./SectionTitle.vue";

// import DemoStory from "./DemoStory.vue";
// import DemoVideo from "./DemoVideo.vue";

export default defineComponent({
    components: {
        SectionTitle,
        // DemoStory,
        // DemoVideo,
    },
    setup() {
        const state = reactive({
            storyBook: false,
            storyVideo: false,
        });

        const items = [
            {
                image: Remarks1,
                title: "Storybooks",
                action: () => { state.storyBook = true; },
            },
            {
                image: Remarks2,
                title: "Activity books",
                action: () => { },
            },
            {
                image: Remarks3,
                title: "Flashcards",
                action: () => { },
            },
            {
                image: Remarks4,
                title: "Storybook Animations",
                action: () => { state.storyVideo = true; },
            },
        ];

        return {
            items,
            ...toRefs(state),
        };
    }
});
</script>

<style lang="scss" scoped>
.green-border {
    border: 0.25rem solid #587525;
}
</style>