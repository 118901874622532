<template>
  <div class="min-h-screen  mx-auto">
    <div class="relative min-h-screen overflow-hidden flex flex-col justify-between">
      <top-bar2 />
      <router-view class="mb-auto" />
      <footer-comp />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TopBar2 from "./TopBar2.vue";
import FooterComp from "./FooterComp.vue";

export default defineComponent({
    components: { TopBar2, FooterComp },
    setup() {
        return {};
    }
});
</script>

<style scoped>
</style>