<template>
  <main class="md:container md:max-w-4xl select-none font-baloo2">
    <h1 class="text-4xl">
      BAKIŞ AÇIMIZ
    </h1>
    <div class="text-xl my-8 space-y-4">
      <p>Allah insanı doğduğu andan itibaren büyük bir kıymet içinde ve büyük bir görev için yaratmıştır. İnsan yaratılmışların en şereflisidir. İnsanın gönlünü ise dağların bile kaldıramadığı yükü en güzel şekilde taşıyacak güçte yaratmıştır. Çocuklar ise Allah ile münasebeti daha çok taze olan yaratılmışların en küçükleridir. Başta anne ve babası olmak üzere Allah’ın onları yerleştirdikleri çevreye emanettirler. Allah her çocuğun bünyesinde farklı yetenekler, ilgi alanları, beceriler ve karakter özellikleri saklamaktadır. Saklanan bu nimetlerin kıymetinin bilinmesine bağlı olarak bütün çocuklar yaratılmışların en şereflisi olacak potansiyele sahiptir. Bu potansiyelin açığa çıkması ise ancak çocukların emanet edildiği kişilerin yani benim, senin ve onun farkındalığı sayesinde mümkün olabilir. Çocukların gönlünde bulunan hakikat tohumları onları merhametle ve hikmetle gözlemleyen yetişkinlerin arasında yeşerir. Onların güçlü ve zayıf yönlerini keşfeden yetişkinler, çocukların kendi yollarını fıtratları ile uyumlu şekilde çizmelerini kolaylaştırmış olurlar. Böylece Allah, çocukların fıtratlarında gizlediği nimetleri çocukların üstünde görmüş olur ve maksat hâsıl olur.</p>
      <p>Dünya yolculuğunun ilk zamanlarını yani erken çocukluk döneminden ilkokul yıllarının sonuna kadar çocukların beslendikleri değerler ve teneffüs ettikleri atmosferler onların hayatlarının geri kalanındaki manevi dünyaları için büyük önem arz etmektedir. İşte bu yüzden çocukların ilk yıllarını manevi hayattan kopuk geçirmemeleri, Müslümanlar olarak değerlerimizin hakim ve geçerli olduğu ortamlarda geçirmeleri onların ruh ve zihin olarak bütün içinde bir erken çocukluk dönemi geçirmelerini sağlar. Çünkü erken çocukluk yıllarında çocukların maruz kaldığı deneyimler onların beyin kıvrımlarında kalıcı değişikliklere yol açar. Adım Adım Değerler Eğitim Programı ile çocuklara ayrı bir branş dersi olarak didaktik yöntemlerle değerlerimizin öğretilmesini değil, çocukların bulundukları ortamın ve onlara rehberlik eden kişilerin bizzat “değerlerin” kendisinin olması gerektiğini düşünüyoruz. Bu yüzden Adım Adım Değerler’de ebeveynler ve öğretmenler iyi birer öğretmen, anne ve baba olmadan önce bilinçli ve hassasiyet sahibi birer Müslüman olarak çocuğun hayatında aktif rol oynamaktadırlar. Öğretmen, anne ve baba kimlikleri Müslüman kimliklerinden sonra gelir. Çocuklar onların ruh hallerinden ve davranışlarından iyi bir Müslüman olmayı önce hisseder, sonra öğrenir ve en son davranış olarak edinirler.</p>
      <p>Adım Adım Değerler, programı hazırlayan ekip tarafından arka plandaki bu bakış açısı ile özenle uzun zaman içinde hazırlandı. Allah’ın bize emanet ettiği çocuklara olan görevimizi eda etmek niyeti ile bu programı Allah’ın kabul etmesini niyaz ediyoruz.</p>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { BookOpenIcon, CollectionIcon, SpeakerphoneIcon } from "@heroicons/vue/solid";

export default defineComponent({
    components: {
    },
    setup() {
        
        return {
         
        };
    },
});
</script>

<style lang="scss" scoped>
</style>