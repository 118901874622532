<template>
  <div
    class="h-20 w-72 select-none m-auto"
    :class="{ filter: disabled, grayscale: disabled }"
  >
    <div
      class="font-CardenioModernBold relative flex h-12 rounded-br-3xl border-4 border-double tracking-wider"
      :style="{ 'background-color': bgcolor, 'border-color': borderColor}"
    >
      <img
        :src="getAbsolutePath(theme.icon.url)"
        class="absolute -top-5 -left-4 h-20 w-20 rounded-full"
      >
      <div class="ml-20 mb-auto mt-auto flex">
        <span
          class="inline-block align-middle uppercase text-lg"
          :style="{ 'color': textColor}"
        >{{ theme.name }}</span>
      </div>
    </div>
    <div />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Theme } from "@/models/theme";
import { getAbsolutePath } from "@/api/api";

export default defineComponent({
    props: {
        theme: { type: Object as PropType<Theme>, required: true },
        disabled: { type: Boolean, required: false },
        bgcolor: { type: String, required: true },
        borderColor: { type: String, required: true },
        textColor: { type: String, required: true },
    },
    setup(props) {
        return {
            theme: props.theme,
            getAbsolutePath,
        };
    }
});
</script>

<style scoped>
</style>